import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from '../components/Navbar';

function Donation() {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
  });
  const [selectedAmount, setSelectedAmount] = useState(null);
  const [customAmount, setCustomAmount] = useState('');
  const [errors, setErrors] = useState({
    phoneError: '',
    donationError: false,
  });

  const navigate = useNavigate();

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      const donationAmount = customAmount || selectedAmount;
      const completeData = { ...formData, donationAmount };
      localStorage.setItem('donationData', JSON.stringify(completeData));
      navigate('/payment', { state: completeData });
    }
  };

  const handleInputChange = ({ target: { name, value } }) => {
    if (name === 'phone') {
      handlePhoneChange(value);
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handlePhoneChange = (value) => {
    const formattedPhone = formatPhoneNumber(value);
    setFormData((prev) => ({ ...prev, phone: formattedPhone }));
    if (errors.phoneError) {
      setErrors((prev) => ({ ...prev, phoneError: '' }));
    }
  };

  const formatPhoneNumber = (value) => {
    const cleaned = value.replace(/\D/g, '').slice(0, 10);
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{0,4})$/);
    return match ? `${match[1]}-${match[2]}${match[3] ? `-${match[3]}` : ''}` : cleaned;
  };

  const validatePhoneOnBlur = () => {
    const phoneRegex = /^\d{3}-\d{3}-\d{4}$/;
    if (!phoneRegex.test(formData.phone)) {
      setErrors((prev) => ({ ...prev, phoneError: 'Please enter a valid phone number in the format XXX-XXX-XXXX.' }));
    }
  };

  const handleDonationSelect = (amount) => {
    setSelectedAmount(amount);
    setCustomAmount('');
    setErrors((prev) => ({ ...prev, donationError: false }));
  };

  const handleCustomAmountChange = ({ target: { value } }) => {
    setCustomAmount(value);
    setSelectedAmount(null);
    setErrors((prev) => ({ ...prev, donationError: false }));
  };

  const validateForm = () => {
    const { firstName, lastName, email, phone } = formData;
    const isDonationValid = selectedAmount || customAmount;
    setErrors((prev) => ({ ...prev, donationError: !isDonationValid }));
    return (
      firstName.trim() &&
      lastName.trim() &&
      validateEmail(email) &&
      phone.trim() &&
      !errors.phoneError &&
      isDonationValid
    );
  };

  const validateEmail = (email) => /\S+@\S+\.\S+/.test(email);

  return (
    <div className="donation bg-gradient-to-b from-blue-50 to-white min-h-screen">
      <Navbar />
      <div className="mt-10 max-w-3xl mx-auto p-8 bg-white shadow-xl rounded-3xl">
        <h2 className="text-3xl font-bold text-gray-700 mb-6 text-center">Your Contact Information</h2>
        <form onSubmit={handleFormSubmit}>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
          {['firstName', 'lastName', 'email'].map((field) => (
            <input
              key={field}
              type={field === 'email' ? 'email' : 'text'}
              name={field}
              value={formData[field]}
              onChange={handleInputChange}
              placeholder={
                field === 'firstName'
                  ? 'First Name*'
                  : field === 'lastName'
                  ? 'Last Name*'
                  : 'Email*'
              }
              className="w-full p-4 border rounded-xl text-gray-600 shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-400"
              required
            />
          ))}

            <input
              type="tel"
              name="phone"
              value={formData.phone}
              onChange={handleInputChange}
              onBlur={validatePhoneOnBlur}
              placeholder="Phone Number*"
              className={`w-full p-4 border rounded-xl text-gray-600 shadow-sm focus:outline-none ${
                errors.phoneError ? 'border-red-500 focus:ring-red-500' : 'focus:ring-blue-400'
              }`}
              required
            />
            {errors.phoneError && <p className="text-red-500 text-sm col-span-2">{errors.phoneError}</p>}
          </div>
          <h2 className="text-2xl font-bold text-gray-700 mb-6 text-center">Donation Amount</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-6">
            {[15, 30, 75, 150, 650].map((amount) => (
              <div
                key={amount}
                onClick={() => handleDonationSelect(amount)}
                className={`p-6 rounded-xl text-center cursor-pointer transition-all duration-300 ${
                  selectedAmount === amount
                    ? 'bg-blue-100 border border-blue-400 shadow-lg transform scale-105'
                    : 'bg-gray-100 hover:bg-blue-50 hover:shadow-md'
                }`}
              >
                <p className="text-xl font-medium text-gray-700">${amount}</p>
                <p className="text-sm text-gray-600">
                  {amount === 15
                    ? 'Sponsor a Hygiene Kit'
                    : amount === 30
                    ? 'Gift a Sweet Case Duffle Bag'
                    : amount === 75
                    ? 'Support Educational Service'
                    : amount === 150
                    ? 'Provide A Bicycle & Helmet'
                    : 'Reunite Siblings At Disneyland'}
                </p>
              </div>
            ))}
            <div
              className={`p-6 rounded-xl text-center transition-all duration-300 ${
                customAmount ? 'bg-blue-100 border border-blue-400 shadow-lg transform scale-105' : 'bg-gray-100 hover:bg-blue-50 hover:shadow-md'
              }`}
            >
              <input
                type="number"
                value={customAmount}
                onChange={handleCustomAmountChange}
                placeholder="Enter Custom Amount"
                className="w-full p-4 border rounded-xl text-gray-600 shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-400"
              />
            </div>
          </div>
          {errors.donationError && (
            <p className="text-red-500 text-sm mb-4 text-center">Please select a donation amount or enter a custom amount.</p>
          )}
          <button
            type="submit"
            className="w-2/4 w-md-2/4 bg-blue-500 text-white p-4 rounded-xl text-lg font-medium hover:bg-blue-600 focus:ring-2 focus:ring-blue-300 focus:outline-none shadow-md"
          >
            Continue
          </button>
        </form>
      </div>
    </div>
  );
}

export default Donation;
