import React, { useState, useEffect } from "react";
import Confetti from "react-confetti";

const ThankYouCelebration = () => {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({ width: window.innerWidth, height: window.innerHeight });
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="ty-info relative min-h-screen bg-white flex flex-col items-center justify-center">
      {/* Confetti Celebration */}
      <Confetti width={windowSize.width} height={windowSize.height} />

      {/* Thank You Message */}
      <div className="bg-gray-100 rounded-lg shadow-lg p-10 text-center">
        <h1 className="text-4xl font-bold text-gray-800">Thank You!</h1>
        <p className="text-lg text-gray-600 mt-4">
          Your donation means a lot to us and will help make a positive impact!
        </p>
        <p className="text-lg text-gray-600 mt-2">We truly appreciate your generosity. 💖</p>
        <div className="mt-6">
          <a href="/">
            <button className="bg-gradient-to-r from-blue-500 to-green-400 text-white font-semibold py-2 px-6 rounded-full shadow-lg hover:scale-105 transform transition-transform">
              Return to Home
            </button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default ThankYouCelebration;
