import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as VolunteeringIcon } from '../assets/images/v2/v_icon.svg';
import { ReactComponent as TeamBuildingIcon } from '../assets/images/v2/tb_icon.svg';
import { ReactComponent as ServiceActivityIcon } from '../assets/images/v2/sa_icon.svg';
import axios from 'axios';
import Navbar from '../components/Navbar';

function Information() {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
  });
  const [selected, setSelected] = useState({
    volunteering: false,
    teamBuilding: false,
    serviceActivity: false,
  });
  const [error, setError] = useState({ phone: '', general: '' });
  const navigate = useNavigate();

  const icons = [
    { key: 'volunteering', Icon: VolunteeringIcon, label: 'Volunteering' },
    { key: 'teamBuilding', Icon: TeamBuildingIcon, label: 'Team Building' },
    { key: 'serviceActivity', Icon: ServiceActivityIcon, label: 'Service Activity' },
  ];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };


  const handlePhoneChange = (e) => {
    const value = e.target.value;
    const formattedPhone = formatPhoneNumber(value);
    setFormData((prev) => ({ ...prev, phone: formattedPhone }));
    if (error.phone) {
      setError((prev) => ({ ...prev, phone: '' }));
    }
  };
  
  const formatPhoneNumber = (value) => {
    const cleaned = value.replace(/\D/g, '').slice(0, 10); // Remove non-digits and limit to 10 characters
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{0,4})$/); // Match the phone number pattern
    return match ? `${match[1]}-${match[2]}${match[3] ? `-${match[3]}` : ''}` : cleaned;
  };
  
  const validatePhoneOnBlur = () => {
    const phoneRegex = /^\d{3}-\d{3}-\d{4}$/; // Updated regex to match formatted phone numbers
    if (!phoneRegex.test(formData.phone)) {
      setError((prevError) => ({
        ...prevError,
        phone: 'Please enter a valid phone number in the format XXX-XXX-XXXX.',
      }));
    }
  };
  

  const handleIconClick = (key) => {
    setSelected((prevSelected) => ({
      ...prevSelected,
      [key]: !prevSelected[key],
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      ...formData,
      volunteering: selected.volunteering ? 'Volunteering' : null,
      teamBuilding: selected.teamBuilding ? 'Team Building' : null,
      serviceActivity: selected.serviceActivity ? 'Service Activity' : null,
    };

    try {
      const response = await axios.post(
        'https://api.fosterlove.com/v2/mobile-booth-info/information/',
        data
      );
      if (response.status === 200) {
        navigate('/thank-you-info');
      } else {
        setError((prevError) => ({
          ...prevError,
          general: 'Something went wrong. Please try again.',
        }));
      }
    } catch {
      setError((prevError) => ({
        ...prevError,
        general: 'Something went wrong. Please try again.',
      }));
    }
  };

  return (
    <div className="information bg-gradient-to-b from-blue-50 to-white min-h-screen">
      <Navbar />
      <div className="mt-10 max-w-3xl mx-auto p-8 bg-white shadow-xl rounded-3xl">
        <h2 className="text-3xl font-bold text-gray-700 mb-6 text-center">
          Your Contact Information
        </h2>
        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
          {['firstName', 'lastName', 'email'].map((field) => (
            <input
              key={field}
              type={field === 'email' ? 'email' : 'text'}
              name={field}
              value={formData[field]}
              onChange={handleInputChange}
              placeholder={
                field === 'firstName'
                  ? 'First Name*'
                  : field === 'lastName'
                  ? 'Last Name*'
                  : 'Email*'
              }
              className="w-full p-4 border rounded-xl text-gray-600 shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-400"
              required
            />
          ))}
            <input
            type="tel"
            name="phone"
            value={formData.phone}
            onChange={handlePhoneChange}
            onBlur={validatePhoneOnBlur}
            placeholder="Phone Number*"
            className={`w-full p-4 border rounded-xl text-gray-600 shadow-sm focus:outline-none ${
              error.phone ? 'border-red-500 focus:ring-red-500' : 'focus:ring-blue-400'
            }`}
            required
          />
          {error.phone && (
            <p className="text-red-500 text-sm col-span-2">
              {error.phone}
            </p>
          )}

          </div>
          <h2 className="text-2xl font-bold text-gray-700 mb-6 text-center">
            Learn More About
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
            {icons.map(({ key, Icon, label }) => (
              <div
                key={key}
                className={`p-6 rounded-xl text-center cursor-pointer transition-all duration-300 ${
                  selected[key]
                    ? 'bg-blue-100 border border-blue-400 shadow-lg transform scale-105'
                    : 'bg-gray-100 hover:bg-blue-50 hover:shadow-md'
                }`}
                onClick={() => handleIconClick(key)}
              >
                <Icon className="mx-auto mb-4 h-12 w-12 text-blue-600" />
                <p className="text-lg font-medium text-gray-700">{label}</p>
              </div>
            ))}
          </div>
          {error.general && (
            <p className="text-red-500 text-sm mb-4 text-center">
              {error.general}
            </p>
          )}
          <button
            type="submit"
            className="w-2/4 w-md-2/4  bg-blue-500 text-white p-4 rounded-xl text-lg font-medium hover:bg-blue-600 focus:ring-2 focus:ring-blue-300 focus:outline-none shadow-md"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
}

export default Information;
