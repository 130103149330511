import { useState, useEffect } from 'react';
import axios from 'axios';
import DropIn from 'braintree-web-drop-in-react';
import Heart from '../assets/images/heart.png';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import Navbar from '../components/Navbar';

function DebitCard() {
  const storedData = localStorage.getItem('donationData');
  const formData = storedData ? JSON.parse(storedData) : null;

  const [clientToken, setClientToken] = useState(null);
  const [dropinInstance, setDropinInstance] = useState(null);
  const [csrfToken, setCsrfToken] = useState(null);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    async function getCsrfToken() {
      const response = await axios.get('https://api.fosterlove.com/v2/payments/generate-client-token/');
      const csrfToken = Cookies.get('csrftoken');
      setCsrfToken(csrfToken);
    }

    getCsrfToken();
  }, []);

  useEffect(() => {
    async function fetchClientToken() {
      try {
        const response = await fetch('https://api.fosterlove.com/v2/payments/generate-client-token/');
        const jsonResponse = await response.json();
        setClientToken(jsonResponse.client_token);
      } catch (err) {
        console.error('Failed to fetch client token:', err);
      }
    }

    fetchClientToken();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setDisabled(true);

    const { payment_method_nonce } = await dropinInstance.requestPaymentMethod();
    const transactionData = {
      first_name: formData.firstName,
      last_name: formData.lastName,
      email: formData.email,
      phone: formData.phone,
      amount: formData.donationAmount,
      program: formData.donationType,
      billing_address: '560 Lambert Rd',
      billing_city_town: 'Brea',
      billing_state: 'California',
      payment_method_nonce: payment_method_nonce,
    };

    try {
      const response = await axios.post('https://api.fosterlove.com/checkout/mobile-booth-donation/', transactionData, {
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': csrfToken,
        },
      });

      if (response.status === 200) {
        localStorage.clear();
        setPaymentSuccess(true);
        navigate('/thank-you');
      } else {
        setError(true);
      }
    } catch (error) {
      setError(true);
    }

    setLoading(false);
    setDisabled(false);
  };

  return (
    <div className="debit-card bg-gradient-to-b from-blue-50 to-white min-h-screen">
      <Navbar />
      <div className="mt-10 max-w-3xl mx-auto p-8 bg-white shadow-xl rounded-3xl">
        <h2 className="text-3xl font-bold text-gray-700 mb-6 text-center">
          Make A Donation
        </h2>
        {loading && (
          <div className="loading-animation flex justify-center items-center">
            <img src={Heart} alt="Loading" className="h-16 w-16 animate-bounce" />
          </div>
        )}
        {clientToken && (
          <DropIn
            options={{
              authorization: clientToken,
              card: {
                cardholderName: false,
              },
            }}
            onInstance={(instance) => setDropinInstance(instance)}
          />
        )}
        <button
          type="submit"
          onClick={handleSubmit}
          disabled={disabled}
          className="w-full bg-blue-500 text-white p-4 rounded-xl text-lg font-medium hover:bg-blue-600 focus:ring-2 focus:ring-blue-300 focus:outline-none shadow-md mt-6"
        >
          {loading ? 'Processing...' : 'Pay with Debit Card'}
        </button>
        {paymentSuccess && (
          <div className="mt-4 text-center text-green-500 font-medium">
            Thank you for your donation!
          </div>
        )}
        {error && (
          <div className="mt-4 text-center text-red-500 font-medium">
            Something went wrong with your payment. Please try another method.
          </div>
        )}
      </div>
    </div>
  );
}

export default DebitCard;
