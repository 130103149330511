import React, { useState, useEffect } from "react";
import FLQr from "../assets/images/fl-qr.png";
import { ReactComponent as Logo } from "../assets/images/fl_logo.svg";
import Confetti from "react-confetti";
import { Link } from "react-router-dom";

function ThankYouInfo() {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({ width: window.innerWidth, height: window.innerHeight });
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="ty-info relative min-h-screen bg-white flex flex-col items-center justify-center">
      <Confetti width={windowSize.width} height={windowSize.height} />

      {/* Logo Centered Above the Card */}
      <div className="logo-container mb-6">
        <Logo className="fl-logo" style={{ height: "100px", width: "100px" }} />
      </div>

      {/* Thank You Card */}
      <div className="thank-you-card bg-gray-100 rounded-lg shadow-lg p-6 text-center relative">
        <h1 className="text-4xl font-bold text-gray-800 mb-4">Thank You!</h1>
        <h2 className="text-2xl font-semibold text-gray-700">
          Your Response Has Been Submitted
        </h2>
        <p className="text-gray-600 mt-4">
          In the meantime, scan the QR code to visit our website!
        </p>
        <img
          src={FLQr}
          alt="QR Code"
          className="qr-code-border mx-auto mt-4 w-32 h-32"
        />

        {/* Button and Teddy Bear Container */}
        <div className="flex flex-col items-center mt-6">
          {/* Button */}
          <Link to="/">
            <button className="bg-gradient-to-r from-blue-500 to-green-400 text-white font-semibold py-2 px-6 rounded-full shadow-lg hover:scale-105 transform transition-transform mb-4">
              Return To Home
            </button>
          </Link>
          {/* Teddy Bear */}
    
        </div>
      </div>
    </div>
  );
}

export default ThankYouInfo;



