import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as MakeADonation } from '../assets/images/v2/make_a_donation.svg';
import { ReactComponent as MoreInformation } from '../assets/images/v2/request_more_information.svg';

const MainMenu = () => {
  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100">
      <h1 className="text-4xl font-bold text-gray-800 mb-8">Welcome!</h1>
      <p className="text-lg text-gray-600 mb-6 text-center px-4">
        What Would You Like To Do Today?
      </p>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 w-full max-w-3xl px-4">
        <Link to="/donation">
          <div className="flex flex-col items-center justify-center p-6 bg-white rounded-lg shadow-md hover:shadow-lg transform hover:scale-105 transition-transform duration-200 cursor-pointer">
            <div className="flex items-center justify-center w-20 h-20 bg-red-100 rounded-full mb-4">
              <MakeADonation className="h-8 w-8 text-red-500" />
            </div>
            <h3 className="text-xl font-semibold text-gray-700 mb-2 text-center">
              Make a Donation
            </h3>
            <p className="text-gray-500 text-center">
              Support a cause and make a difference today.
            </p>
          </div>
        </Link>
        <Link to="/information">
          <div className="flex flex-col items-center justify-center p-6 bg-white rounded-lg shadow-md hover:shadow-lg transform hover:scale-105 transition-transform duration-200 cursor-pointer">
            <div className="flex items-center justify-center w-20 h-20 bg-blue-100 rounded-full mb-4">
              <MoreInformation className="h-8 w-8 text-blue-500" />
            </div>
            <h3 className="text-xl font-semibold text-gray-700 mb-2 text-center">
              Request More Information
            </h3>
            <p className="text-gray-500 text-center">
              Get answers to your questions or learn more about us.
            </p>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default MainMenu;
