import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import { ReactComponent as Logo } from '../assets/images/fl_logo.svg';
import BackArrow from './BackArrow';

function Navbar() {
  const location = useLocation();

  return (
    <div className="bg-white shadow-md">
      {location.pathname !== '/' && (
        <div className="max-w-screen-lg mx-auto flex items-center justify-between p-4">
          {/* Back Arrow */}
          <div>
            <BackArrow route={-1} />
          </div>

          {/* Logo (Centered and acts as Home button) */}
          <Link to="/" className="flex items-center justify-center">
            <Logo className="w-16 h-16 cursor-pointer" />
          </Link>

          {/* Placeholder for alignment (same width as BackArrow) */}
          <div className="w-8"></div>
        </div>
      )}
    </div>
  );
}

export default Navbar;
