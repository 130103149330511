import React, { useState, useEffect } from "react";
import axios from "axios";
import DropIn from "braintree-web-drop-in-react";
import Cookies from "js-cookie";
import { useLocation, useNavigate } from "react-router-dom";
import VenmoQRCode from "../assets/images/venmo-qr.png";
import Logo from "../assets/images/fl_logo.svg";
import VenmoLogo from "../assets/images/venmo-logo.png";

const Payment = () => {
  const location = useLocation();
  const donationData = location.state;

  const [clientToken, setClientToken] = useState(null);
  const [dropinInstance, setDropinInstance] = useState(null);
  const [csrfToken, setCsrfToken] = useState(null);
  const [showCard, setShowCard] = useState(false);
  const [showGPay, setShowGPay] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  const handleReturnHome = () => {
    navigate("/");
  };

  useEffect(() => {
    const fetchClientToken = async () => {
      try {
        const response = await axios.get(
        //   "http://localhost:8000/v2/payments/generate-client-token/"
        "https://api.fosterlove.com/v2/payments/generate-client-token/"
        );
        setClientToken(response.data.client_token);
        const csrf = Cookies.get("csrftoken");
        setCsrfToken(csrf);
      } catch (error) {
        console.error("Failed to fetch client token:", error);
      }
    };

    fetchClientToken();
  }, []);

  useEffect(() => {
    console.log('DropIn Instance:', dropinInstance);
  }, [dropinInstance]);

  useEffect(() => {
    console.log('Client Token:', clientToken);
  }, [clientToken]);
  



const handleCardSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setDisabled(true);
  
    try {
      if (!dropinInstance) {
        throw new Error('DropIn instance is not initialized.');
      }
      const { nonce } = await dropinInstance.requestPaymentMethod();
      if (!nonce) {
        throw new Error('No nonce returned from DropIn');
      }
  
      console.log("Ayush", nonce);
  
      const transactionData = {
        first_name: donationData.firstName,
        last_name: donationData.lastName,
        email: donationData.email,
        phone: donationData.phone,
        amount: donationData.donationAmount,
        program: 'where-its-needed-most',
        payment_method_nonce: nonce,
      };
  
      const response = await axios.post('https://api.fosterlove.com/v2/payments/process-payment/', transactionData, {
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': csrfToken,
        },
      });
  
      if (response.status === 200) {
        navigate('/thank-you-celebration'); 
      } else {
        setError(true);
      }
    } catch (error) {
      console.error('Payment Error:', error);
      setError(true);
    }
  
    setLoading(false);
    setDisabled(false);
  };
  

  

  return (
    <div className="max-w-md mx-auto p-6 bg-white shadow-md rounded-lg">
      <h2 className="text-xl font-bold mb-4">Select The Payment Method</h2>

      {/* Payment Options */}
      <div className="flex items-center space-x-4 mb-4">
        <button
          className={`w-1/2 py-2 border rounded-lg transition-transform transform hover:scale-105 ${
            showCard
              ? "bg-gray-100 border-black shadow-lg"
              : "border-gray-300 shadow"
          }`}
          onClick={() => {
            setShowCard(true);
            setShowGPay(false);
            setError(false);
          }}
          style={{
            boxShadow: "2px 2px 8px rgba(0, 0, 0, 0.2)",
            borderRadius: "12px",
          }}
        >
          Card
        </button>
        <button
          className={`w-1/2 py-2 border rounded-lg transition-transform transform hover:scale-105 ${
            showGPay
              ? "bg-gray-100 border-black shadow-lg"
              : "border-gray-300 shadow"
          }`}
          onClick={() => {
            setShowGPay(true);
            setShowCard(false);
            setError(false);
          }}
          style={{
            boxShadow: "2px 2px 8px rgba(0, 0, 0, 0.2)",
            borderRadius: "12px",
          }}
        >
          Venmo
        </button>
      </div>

      {showCard && (
        <>
          {clientToken && (
            <DropIn
              options={{
                authorization: clientToken,
                card: {
                  cardholderName: true,
                },
              }}
              onInstance={(instance) => setDropinInstance(instance)}
            />
          )}
          <button
            onClick={handleCardSubmit}
            disabled={disabled}
            className="w-full mt-4 py-2 bg-black text-white rounded-lg font-bold"
          >
            {loading ? "Processing..." : "Pay with Card"}
          </button>
          {/* Payment Success/Error Messages */}
      {paymentSuccess && (
        <div className="mt-4 p-4 bg-green-100 text-green-600 rounded-lg">
          Payment Successful! Thank you for your donation.
        </div>
      )}

      {error && (
        <div className="mt-4 p-4 bg-red-100 text-red-600 rounded-lg">
          Payment failed. Please try again.
        </div>
      )}
        </>
      )}

      {showGPay && (
       <div className="thank-you-page-venmo text-center">
       <h1 className="text-xl font-semibold mb-2">
         Thank You For Changing a Life Today!
       </h1>
       <div className="flex flex-col items-center space-y-2">
         <img
           src={VenmoLogo}
           alt="Venmo Logo"
           className="w-20 h-20 object-contain"
         />
         <img
           src={VenmoQRCode}
           alt="Venmo QR Code"
           className="w-40 h-40 object-cover rounded-lg shadow-md"
         />
       </div>
       <button
         onClick={handleReturnHome}
         className="w-3/4 bg-blue-500 text-white p-2 rounded-xl text-lg font-medium hover:bg-blue-600 focus:ring-2 focus:ring-blue-300 focus:outline-none shadow-md mt-4"
       >
         Return To Home 
       </button>
     </div>
     
      )}

      
    </div>
  );
};

export default Payment;
